@import url(https://fonts.googleapis.com/css2?family=Pangolin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navbar-wrapper {
  z-index: 9999999 !important;
}

body {
  overflow-x: hidden !important;
}

.form-check {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-top: 15px;
}

.form-check input {
  width: 35px;
  height: 35px;
  box-shadow: none;
}

.form-check input:focus {
  box-shadow: none;
}

.form-check .gdpr-line {
  margin-left: 10px;
  margin-bottom: 0 !important;
  font-weight: 400;
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-self: center;
          align-self: center;
}

.button {
    position: relative;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
    background-color: #e21a37;
    color: #fff;
    box-shadow: 0 3px 18px rgb(226 26 55 / 50%);
    border: none;
    padding: 8px;
    font-size: 1.75em;
    z-index: 999;
    cursor: pointer;
    transition: .33s all ease-in-out;
}

.button--inline {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.button .button__arrow {
  display: block;
  width: 35px;
  margin: 0 auto;
  margin-top: 7.5px;
  -webkit-transform: translateY(0) rotate(270deg) !important;
          transform: translateY(0) rotate(270deg) !important;
  -webkit-animation: bounce-top 4s infinite both;
  animation: bounce-top 4s infinite both;
}

.button .button__add {
  width: 27.5px;
}

@-webkit-keyframes bounce-top {
  0% {
            -webkit-transform: translateY(-10px) rotate(270deg);
                    transform: translateY(-10px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
            -webkit-transform: translateY(-8px) rotate(270deg);
                    transform: translateY(-8px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  65% {
            -webkit-transform: translateY(-7px) rotate(270deg);
                    transform: translateY(-7px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  82% {
            -webkit-transform: translateY(-6px) rotate(270deg);
                    transform: translateY(-6px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  93% {
            -webkit-transform: translateY(-4px) rotate(270deg);
                    transform: translateY(-4px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
            -webkit-transform: translateY(0px) rotate(270deg);
                    transform: translateY(0px) rotate(270deg);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
  }
  100% {
            -webkit-transform: translateY(0px) rotate(270deg);
                    transform: translateY(0px) rotate(270deg);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
            -webkit-transform: translateY(-10px) rotate(270deg);
                    transform: translateY(-10px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
            -webkit-transform: translateY(-8px) rotate(270deg);
                    transform: translateY(-8px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  65% {
            -webkit-transform: translateY(-7px) rotate(270deg);
                    transform: translateY(-7px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  82% {
            -webkit-transform: translateY(-6px) rotate(270deg);
                    transform: translateY(-6px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  93% {
            -webkit-transform: translateY(-4px) rotate(270deg);
                    transform: translateY(-4px) rotate(270deg);
            -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
            -webkit-transform: translateY(0px) rotate(270deg);
                    transform: translateY(0px) rotate(270deg);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
  }
  100% {
            -webkit-transform: translateY(0px) rotate(270deg);
                    transform: translateY(0px) rotate(270deg);
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.button p {
  font-weight: 500;
}

.button--active {
  background-color: #97091e;
}

.button:hover {
  background-color: #cb1731;
}

.header-wrap {
  position: fixed;
  top: 0;
  width: 100vw !important;
}

.modal-warning {
  display: none;
  text-align: center;
  color: #9f3a38;
}

.modal-success {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  top: 0;
  left: 0;
  padding: 16px;
  background: rgba(0,0,0,.33);
  z-index: 99999999;
}

.modal-success p {
  font-size: 26px;
  padding: 70px 26px;
  border-radius: 6px;
  background: #fff;
  position: relative;
  color: #008700;
  line-height: 115%;
  font-weight: bold;
  text-align: center;
}

.modal-success p::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 25px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 60px;
  height: 4px;
  border-radius: 4px;
  background: #008700;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.veritel__adresa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.veritel__adresa #veritel_co::-webkit-input-placeholder, .veritel__adresa #veritel_cp::-webkit-input-placeholder {
  font-size: 11px;
}

.veritel__adresa #veritel_co:-ms-input-placeholder, .veritel__adresa #veritel_cp:-ms-input-placeholder {
  font-size: 11px;
}

.veritel__adresa #veritel_co::placeholder, .veritel__adresa #veritel_cp::placeholder {
  font-size: 10.5px;
}

.veritel__adresa input {
  margin: 2.5px;
  font-size: 13px;
  padding: 8px !important;
}

.veritel__adresa input:nth-child(1) {
  margin-left: 0;
}

.veritel__adresa input:nth-last-child(1) {
  margin-right: 0;
}

.pop-up-alert {
  display: none;
  background: #fff;
  box-shadow: 0 3px 12px rgba(94, 94, 94, 0.5);
  border-radius: 8px;
  position: absolute;
  text-align: center;
  padding: 10px 15px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 99999;
}

.pop-up-alert__text {
  font-weight: 500;
}

.pop-up-alert__btn {
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  transition: .25s all ease-in-out;
}

.pop-up-alert__btn--yes {
  background-color: #e21a37;
  color: #fff;
}

.pop-up-alert__btn--yes:hover {
  background-color: #cb1731;
}

.pop-up-alert__btn--no {
  background-color: #eee;
}

.pop-up-alert__btn--no:hover {
  background-color: #e4e4e4;
}

.contact__error-message, .contact__success-message, .pohledavky__error-message, .dluznici__error-message, .dluznici__success-message, .pohledavky__info, .pohledavky__success-message {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  margin-top: 20px;
  color: #fff;
  text-align: center;
  position: relative;
  transition: .5s all ease-in-out;
  z-index: 999;
}

.contact__error-message, .contact__success-message, .pohledavky__error-message, .dluznici__error-message, .dluznici__success-message, .pohledavky__success-message {
  display: none;
  opacity: 0;
}

.contact__error-message h3, .contact__success-message h3, .pohledavky__error-message h3, .dluznici__error-message h3, .dluznici__success-message h3 {
  font-size: 16px;
  margin: 0;
}

.pohledavky__error-message, .dluznici__error-message, .dluznici__success-message {
  margin-bottom: 25px;
}

.contact__error-message, .pohledavky__error-message, .dluznici__error-message {
  max-width: 660px;
  border: 2px solid #cb1731;
  color: #871c08;
  background-color: #f78b77;
  font-weight: 500;
  font-size: 1.25em;
}

.contact__success-message, .pohledavky__success-message {
  max-width: 660px;
  border: 4px solid #00b11d;
  background-color: #e3ffe8;
  color: #0e8518;
  font-weight: 500;
  font-size: 1.25em;
}

.pohledavky__error, .dluznici__error {
  max-width: 660px;
  border: 2px solid #e21a37 !important;
  color: #e21a37;
  transition: .3s all ease-in-out;
}

#type1-var, #type2-var, #amount-var, #company-var, #name-var, #email-var, #tel-var {
  position: absolute;
  visibility: hidden;
}

.lead-form-page,
.dluznici-page,
.pohledavky-page,
.calculator-page,
.pravidelne-page,
.dokumenty-page,
.form-page {
  max-width: 1060px;
  margin-top: 150px;
  margin-right: auto;
  margin-left: auto;
}

.landing-page {
  margin-top: 150px;
}

.finio-app {
  font-family: 'Barlow', sans-serif;
  width: 100vw;
  min-height: 70vh;
  background-attachment: fixed;
  position: relative;
  line-height: normal;
}

.finio-app p {
  margin: 0;
}

.finio-app input, .finio-app button, .finio-app textarea {
  outline: none;
  transition: .15s all ease-in-out;
}

.finio-app input:focus, .finio-app input:active {
  box-shadow: 0 1px 6px rgba(41, 31, 33, 0.5);
}

.finio-app .previous-step {
  display: none;
  position: absolute;
  bottom: 100px;
  left: 50px;
  color: #fff;
  background-color: #e21a37;
  box-shadow: 0 3px 18px rgba(226, 26, 55, 0.5);
  border-radius: 1px;
  padding: 12px 18px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
}

.finio-app .previous-step p {
  z-index: 9;
  position: relative;
}

.finio-app .previous-step:hover::before {
  width: 100%;
}

.finio-app .previous-step::before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 0%;
  background: #cb1731;
  transition: .33s all ease-in-out;
}

.speech-bubbles {
  position: absolute;
  width: 100%;
  bottom: 0%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow: hidden;
  /*     &_element {
        width: 260px;
        height: 260px;
        background-image:url(../assets/bubble.png);
        background-repeat: no-repeat;
        background-size: contain;
    } */
}

.speech-bubbles_element--right,
.speech-bubbles_element--left {
  font-family: 'Pangolin', cursive;
}

.speech-bubbles_element--right img,
.speech-bubbles_element--left img {
  width: 600px;
  z-index: 9;
  position: relative;
}

.speech-bubbles_element--right .bubble,
.speech-bubbles_element--left .bubble {
  position: absolute;
  width: 320px;
  height: 240px;
  /*background-image: url(../assets/speech-buble.svg);*/
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
}

.speech-bubbles_element--right .bubble p,
.speech-bubbles_element--left .bubble p {
  transition: .15s all ease-in-out;
  font-size: 1.2em;
}

.speech-bubbles_element--right .bubble p strong, .speech-bubbles_element--right .bubble p b,
.speech-bubbles_element--left .bubble p strong,
.speech-bubbles_element--left .bubble p b {
  color: #cb1731;
}

.speech-bubbles_element--left img {
  transform: translate(-370px, 70px) rotate(25deg);
  -webkit-transform: translate(-370px, 70px) rotate(25deg);
}

.speech-bubbles_element--left .bubble {
  left: 27px;
  top: 2px;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.speech-bubbles_element--left .bubble p {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
  padding: 50px 20px 20px 40px;
}

.speech-bubbles_element--right img {
  -webkit-transform: scale(-1, 1) translate(-270px, 170px);
  transform: scale(-1, 1) translate(-270px, 170px);
}

.speech-bubbles_element--right .bubble {
  right: 50px;
  top: 10px;
}

.speech-bubbles_element--right .bubble p {
  padding: 55px 40px 20px 20px;
  /*font-size: 18px;*/
}

/**
 * Checkboxes
 */
.checkbox {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.checkbox > input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.checkbox__icon {
  display: inline-block;
  /* Default State */
  color: #888;
  /* Active State */
}

input[type="checkbox"]:checked ~ .checkbox__icon {
  color: #e21a37;
}

.checkbox__label {
  display: inline-block;
  font-size: 16px;
  margin-left: 8px !important;
}

.checkbox__label small {
  font-size: 12px;
}

.checkbox__label small p {
  display: inline-block;
  margin-left: -10px;
}

.checkbox__label small p::first-letter {
  visibility: hidden;
}

/* IE6-8 Fallback */
@media \0screen\,screen\9 {
  .checkbox__icon {
    display: none;
  }
  .checkbox > input[type="checkbox"] {
    position: static;
  }
}

/****************************
 ****************************
 ****************************
 * Helpers
 */
.checkbox__icon:before {
  font-family: "icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--check:before, input[type="checkbox"]:checked ~ .checkbox__icon:before {
  content: "\e601";
}

.icon--check-empty:before, .checkbox__icon:before {
  content: "\e600";
}

@font-face {
  font-family: "icons";
  font-weight: normal;
  font-style: normal;
  src: url("data:application/x-font-woff;charset=utf-8;base64,d09GRk9UVE8AAAR4AAoAAAAABDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAPgAAAD4fZUAVE9TLzIAAAHsAAAAYAAAAGAIIvy3Y21hcAAAAkwAAABMAAAATBpVzFhnYXNwAAACmAAAAAgAAAAIAAAAEGhlYWQAAAKgAAAANgAAADYAeswzaGhlYQAAAtgAAAAkAAAAJAPiAedobXR4AAAC/AAAABgAAAAYBQAAAG1heHAAAAMUAAAABgAAAAYABlAAbmFtZQAAAxwAAAE5AAABOUQYtNZwb3N0AAAEWAAAACAAAAAgAAMAAAEABAQAAQEBCGljb21vb24AAQIAAQA6+BwC+BsD+BgEHgoAGVP/i4seCgAZU/+LiwwHi2v4lPh0BR0AAAB8Dx0AAACBER0AAAAJHQAAAO8SAAcBAQgPERMWGyBpY29tb29uaWNvbW9vbnUwdTF1MjB1RTYwMHVFNjAxAAACAYkABAAGAQEEBwoNL2X8lA78lA78lA77lA6L+HQVi/yU+JSLi/iU/JSLBd83Fffsi4v77Pvsi4v37AUOi/h0FYv8lPiUi4v33zc3i/s3++yLi/fs9zeL398F9wCFFftN+05JzUdI9xr7GveR95FHzwUO+JQU+JQViwwKAAMCAAGQAAUAAAFMAWYAAABHAUwBZgAAAPUAGQCEAAAAAAAAAAAAAAAAAAAAARAAAAAAAAAAAAAAAAAAAAAAQAAA5gEB4P/g/+AB4AAgAAAAAQAAAAAAAAAAAAAAIAAAAAAAAgAAAAMAAAAUAAMAAQAAABQABAA4AAAACgAIAAIAAgABACDmAf/9//8AAAAAACDmAP/9//8AAf/jGgQAAwABAAAAAAAAAAAAAAABAAH//wAPAAEAAAAAAACkYCfgXw889QALAgAAAAAAz65FuwAAAADPrkW7AAD/4AIAAeAAAAAIAAIAAAAAAAAAAQAAAeD/4AAAAgAAAAAAAgAAAQAAAAAAAAAAAAAAAAAAAAYAAAAAAAAAAAAAAAABAAAAAgAAAAIAAAAAAFAAAAYAAAAAAA4ArgABAAAAAAABAA4AAAABAAAAAAACAA4ARwABAAAAAAADAA4AJAABAAAAAAAEAA4AVQABAAAAAAAFABYADgABAAAAAAAGAAcAMgABAAAAAAAKACgAYwADAAEECQABAA4AAAADAAEECQACAA4ARwADAAEECQADAA4AJAADAAEECQAEAA4AVQADAAEECQAFABYADgADAAEECQAGAA4AOQADAAEECQAKACgAYwBpAGMAbwBtAG8AbwBuAFYAZQByAHMAaQBvAG4AIAAxAC4AMABpAGMAbwBtAG8AbwBuaWNvbW9vbgBpAGMAbwBtAG8AbwBuAFIAZQBnAHUAbABhAHIAaQBjAG8AbQBvAG8AbgBHAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4AAAAAAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==") format("woff");
}


/* button container */
.button-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.button-container .button {
  max-width: 260px;
  font-size: 2rem;
  margin-top: 0;
}

.button-container__dots {
  height: 110px;
  border-left: 5px dotted #333;
}

.button-container__dots::before {
  content: 'nebo';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #e1e5e6;
  padding: 7.5px 5px;
}

@media (max-width: 1600px) {
  .landing-page,
  .lead-form-page,
  .dluznici-page,
  .calculator-page,
  .pravidelne-page,
  .dokumenty-page,
  .form-page {
    max-width: 760px;
    margin-top: 150px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 1260px) {
  .button-container__dots{
    display: none;
  }
  .button-container .button {
    max-width: 90%;
  }
  .finio-app {
    min-height: auto;
  }
  .modal-dialog {
    margin-left: auto !important;
  }
  .navbar .container {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .button p, .calculator__next-btn p, .documents__next-btn p, .contact__fake-btn p, .pohledavky__next-btn p, .pohledavky__add button p, .dluznici__next-btn p, .dluznici__add button p, .dluznici__add-new button p {
    font-size: .85em;
  }
  .calculator__next-btn svg, .calculator__next-btn img, .documents__next-btn svg, .documents__next-btn img, .contact__fake-btn svg, .contact__fake-btn img, .pohledavky__next-btn svg, .pohledavky__next-btn img, .pohledavky__add button svg, .pohledavky__add button img, .dluznici__next-btn svg, .dluznici__next-btn img, .dluznici__add button svg, .dluznici__add button img, .dluznici__add-new button svg, .dluznici__add-new button img {
    width: 25px;
  }
  .finio-app {
    padding: 0 5px;
  }
  .finio-app .previous-step {
    position: relative;
    left: 0;
    bottom: 20px;
    margin-left: 5px;
    margin-top: 35px;
  }
  .calculator-page,
  .pravidelne-page,
  .dokumenty-page,
  .pohledavky-page,
  .dluznici-page,
  .form-page,
  .lead-form-page {
    margin-top: 120px;
  }
  .landing-page {
    margin-top: 120px;
  }
  .speech-bubbles {
    width: 100%;
    z-index: 9;
    display: block;
    left: 0;
    padding: 5px;
    position: relative;
    margin-top: 37.5px;
  }
  .speech-bubbles_element--left img, .speech-bubbles_element--right img {
    display: none !important;
  }
  .speech-bubbles_element--left .bubble, .speech-bubbles_element--right .bubble {
    width: auto;
    height: auto;
    z-index: 9999;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    background: #fff;
  }
  .speech-bubbles_element--left .bubble p, .speech-bubbles_element--right .bubble p {
    font-size: 1.15em !important;
  }
  .speech-bubbles_element--left .bubble {
    background-image: none !important;
    left: 0px;
    top: 0;
  }
  .speech-bubbles_element--left .bubble p {
    padding: 10px !important;
  }
  .speech-bubbles_element--right .bubble {
    background-image: none !important;
    right: 0px;
    top: 0;
    margin-top: 10px;
  }
  .speech-bubbles_element--right .bubble p {
    padding: 10px !important;
  }
  .documents {
    width: auto;
    height: auto;
  }
  .documents__box {
    height: auto;
    margin-top: 30px;
  }
  .pravidelne-page__type-first {
    margin-top: 75px !important;
  }
  .contact__error-message, .contact__success-message, .pohledavky__error-message {
    top: 0;
  }
  .sidenav-open .respond_sidenav {
    z-index: 99999999 !important;
  }
  .step-view {
    position: relative;
    width: 100%;
    margin-top: -25px;
    top: auto;
    right: auto;
    text-align: center;
  }
  .step-view p {
    display: none;
  }
  .step-view__inner p {
    display: inline-block;
  }
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-13 13:38:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-13 23:35:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    opacity: 1;
  }
}

.slide-out-top {
  -webkit-animation: slide-out-top 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-13 23:43:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}

.success-form-page {
  width: 100%;
  height: 100vh;
  background: #e1e5e6 url(https://www.finio.cz/_static/img/bg_page.png) repeat-x;
  font-family: 'Barlow', sans-serif;
}

.success-form-page__box {
  text-align: center;
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 6px 16px rgba(5, 5, 5, 0.25);
  overflow: hidden;
}

.success-header {
  padding: 20px 12px;
  background-color: #51c75b;
}

.success-info {
  padding: 24px 12px;
}

.success-info h1 {
  color: #111;
}

.success-info h4 {
  margin-top: 10px;
  color: #333;
}

.success-back {
  display: inline-block;
  text-decoration: none;
  margin-top: 40px;
  color: #fff;
  background-color: #e21a37;
  box-shadow: 0 3px 18px rgba(226, 26, 55, 0.5);
  border-radius: 1px;
  padding: 12px 18px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
}
/*# sourceMappingURL=style.css.map */



.header {
  text-align: center;
  position: relative;
}

.header__title {
  color: #e21a37;
  padding-top: 30px;
  font-weight: bold;
}

.header__title h1 {
  font-size: 2.5em;
}

.header__subtitle {
  display: inline-block;
  margin-top: 20px;
  font-size: 20px;
  color: #666;
}

.header__arrow {
  margin-top: 15px;
  color: #e21a37;
  display: block;
  font-size: 21px;
}

.header__subtitle--box {
  background-color: #bbb;
  padding: 24px 18px;
  color: #fff;
  border-radius: 8px;
  line-height: 200%;
}

.header__subtitle--box h2 {
  font-weight: 600;
  font-size: 25px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.header__subtitle--text {
  color: #222;
  line-height: 150%;
}

.header__subtitle--text b {
  font-size: 22px;
  margin-top: 10px;
  display: block;
}

.header__text {
  font-size: 1.25em;
  font-weight: 500;
  color: #444;
  line-height: 1.5;
  display: block;
  margin-top: 15px !important;
}

.pravidelne-page__type-first {
  margin-top: 200px !important;
}

.type-first {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 40px;
}

.type-first a {
  text-decoration: none;
}

.type-first__buttons {
  display: -webkit-flex;
  display: flex;
}

.type-first__dropdown {
  display: none;
  position: absolute;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  text-align: center;
  z-index: 99;
  transform: translateY(-12px);
  -webkit-transform: translateY(-12px);
  margin: 0 15px;
  padding: 0 38.5px;
  padding-top: 10px;
  padding-bottom: 16px;
  background: #e21a37;
  border-radius: 8px;
}

.type-first__dropdown a {
  display: block;
  margin-top: 16px;
  color: #fff;
  font-size: 19.25px;
  font-weight: 200;
  transition: .33s all ease-in-out;
}

.type-first__dropdown a:hover {
  color: #ddd;
}

.type-first__button {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 999;
  background-color: #e21a37;
  box-shadow: 0 3px 18px rgba(226, 26, 55, 0.5);
  border-radius: 8px;
  padding: 0 42px;
  height: 130px;
  color: #fff;
  font-size: 25px;
  margin: 0 15px;
  line-height: 130%;
  cursor: pointer;
}

.type-first__button svg {
  width: 20px;
  z-index: 9;
  margin-left: 5px;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

.type-first__button p {
  z-index: 9;
}

.type-first__button:hover::before {
  width: 100%;
}

.type-first__button::before {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 0%;
  background: #cb1731;
  transition: .33s all ease-in-out;
}

.type-first__button--transparent {
  background-color: transparent;
  border: 3px solid #e21a37;
  color: #e21a37;
  transition: .3s all ease-in-out;
  height: 80px;
  font-size: 22px;
  z-index: 9;
  margin-bottom: 35px;
}

.type-first__button--transparent:hover {
  color: #fff;
}

.type-first__button--transparent::before {
  background: #e21a37;
}

@media (max-width: 768px) {
  .header {
    font-size: 12px;
  }
  .header__subtitle {
    margin-top: 20px;
  }
  .header__arrow {
    margin-top: 15px;
  }
  .type-first {
    margin-top: 30px;
  }
  .type-first__button {
    padding: 0 18px;
    height: 95px;
    font-size: 18px;
    margin: 0 10px;
  }
  .type-first__dropdown {
    margin-left: 10px;
    padding: 0px 17.5px;
    padding-top: 10px;
    padding-bottom: 18px;
  }
  .type-first__dropdown a {
    font-size: 16px;
  }
  .type-first__button--transparent {
    margin-bottom: 20px;
    z-index: 9;
  }
  .header__subtitle--box h2 {
    font-size: 18px;
  }
}
/*# sourceMappingURL=home.css.map */
.contact {
  position: relative;
  max-width: 480px;
  margin: 0 auto;
  z-index: 99999;
}

.contact .gdpr-link {
  color: inherit;
  text-decoration: underline;
}

.contact small {
  font-size: 1.125em;
  font-weight: 500;
}

.contact a {
  text-decoration: none;
}

.contact__form {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 18px;
}

.lead__form hr {
  height: 2px;
  width: 100%;
  background-color: #b3b3b3;
}

.contact__form input[type="text"],
.contact__form input[type="email"],
.contact__form input[type="number"],
.contact__form textarea {
  width: 100%;
  padding: 14px 12px;
  border-radius: 1px;
  border: 1px solid #eeecec;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  margin-top: 7px;
  font-family: 'Barlow', sans-serif;
  font-size: 1.25em;
  z-index: 999;
  transition: .33s all ease-in-out;
}

.contact__form input[type="text"]:focus, .contact__form input[type="text"]:active,
.contact__form input[type="email"]:focus,
.contact__form input[type="email"]:active,
.contact__form input[type="number"]:focus,
.contact__form input[type="number"]:active,
.contact__form textarea:focus,
.contact__form textarea:active {
  box-shadow: 0 1px 6px rgba(41, 31, 33, 0.5);
}

.contact__form textarea {
  resize: none;
}

.contact__fake-btn {
  text-align: center;
  margin-top: 25px;
}

.lead-label {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .contact {
    width: auto;
  }
  .contact__form input,
  .contact__form textarea {
    font-size: 14px;
    padding: 10px 6px;
  }
  .contact__form input[type="text"], .contact__form input[type="email"], .contact__form input[type="number"], .contact__form textarea {
    padding: 12px 8px;
    font-size: 1em;
  }
}
/*# sourceMappingURL=lead.css.map */
.pohledavky-page {
  text-align: center;
}

.button-container {
  max-width: 660px;
}

.pohledavky {
  max-width: 660px;
  margin: 0 auto;
  margin-top: 25px;
  text-align: left;
  position: relative;
  z-index: 9999;
}

.pohledavky__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.pohledavky__col {
  width: 100%;
  margin: 0 10px;
  text-align: center;
}

.pohledavky__col .input-full, .pohledavky__col .textarea-full {
  width: 100%;
}

.pohledavky__col .select-part {
  width: 75%;
}

.pohledavky__col .input-part {
  width: calc(25% - 5px);
  margin-left: 5px;
}

.pohledavky__col .input-wrapper {
  width: 100%;
  position: relative;
  margin-top: 8px;
}

.pohledavky__col .input-wrapper img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: .2s all ease-in-out;
}

.pohledavky__col .input-wrapper img:hover {
  -webkit-transform: translateY(-50%) scale(1.05);
          transform: translateY(-50%) scale(1.05);
}

.pohledavky__col input, .pohledavky__col select, .pohledavky__col textarea {
  padding: 7px 6px;
  border-radius: 1px;
  border: 1px solid #eeecec;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  font-family: 'Barlow', sans-serif;
  font-size: 1.2em;
  z-index: 999;
}

.pohledavky__col input:disabled, .pohledavky__col select:disabled, .pohledavky__col textarea:disabled {
  background: rgba(255, 255, 255, 0.5);
  -webkit-filter: blur(0.85px);
          filter: blur(0.85px);
}

.pohledavky__col textarea {
  resize: none;
}

.pohledavky__type {
  font-size: 1em;
  padding: 20px 12px;
  margin-bottom: 25px;
  border-radius: 12px;
}

.pohledavky__send {
  display: block;
  text-decoration: none;
}

.pohledavky__info {
  position: relative;
  padding: 20px;
  color: #111;
  background-color: #fff;
  border: 2px solid #ddd;
  font-weight: 500;
  font-size: 1.25em;
}

.pohledavky__info img {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
}

.pohledavky__controller {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.pohledavky__count {
  margin: 10px !important;
}

.pohledavky__count b {
  font-size: 1.25em;
}

.pohledavky__add-new {
  cursor: pointer;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 25px 10px;
  z-index: 999999;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 12px rgba(85, 85, 85, 0.15);
  transition: .3s all ease-in-out;
}

.pohledavky__add-new p {
  font-weight: bold;
  margin-right: 7.5px;
  margin-top: 0;
}

.pohledavky__add-new p, .pohledavky__add-new .button {
  padding: 12px;
  transition: inherit;
}

.pohledavky__add-new .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pohledavky__add-new:hover {
  box-shadow: 0 1px 12px rgba(85, 85, 85, 0.225);
}

.pohledavky__add-new:hover .button {
  background-color: #cb1731;
}

.pohledavky__add-new:hover p {
  letter-spacing: .5px;
}

@media (max-width: 1560px) {
  .pohledavky, .button-container {
    max-width: 590px;
  }
}

@media (max-width: 768px) {
  .pohledavky__col {
    width: 100%;
    margin: 0 4px;
  }
  .pohledavky__col input, .pohledavky__col select, .pohledavky__col textarea {
    font-size: .9em;
    padding: 6px 3.5px;
  }
}
/*# sourceMappingURL=receivable.css.map */
.contact__fake-btn {
  padding: 8px 18px !important;
}
/*# sourceMappingURL=personal.css.map */
header .navbar {
    background-color: #fff !important;
}

.navbar-scale.navbar-scale-down {
    position: relative !important;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
}

.navbar-scale.navbar-scale-down .navbar-brand img {
    height: 55px;
}

.navbar-scale.navbar-scale-down .navbar-brand {
    height: 80px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.navbar-scale.navbar-scale-down .navbar-nav > li > a {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
}

.copyright, .bottom-footer .infoFooter {
    font-size: 13px !important;
}
