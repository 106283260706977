header .navbar {
    background-color: #fff !important;
}

.navbar-scale.navbar-scale-down {
    position: relative !important;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
}

.navbar-scale.navbar-scale-down .navbar-brand img {
    height: 55px;
}

.navbar-scale.navbar-scale-down .navbar-brand {
    height: 80px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.navbar-scale.navbar-scale-down .navbar-nav > li > a {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
}

.copyright, .bottom-footer .infoFooter {
    font-size: 13px !important;
}